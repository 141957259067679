<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-row no-gutters>
        <v-col>
          <v-card class="pa-2 ma-2" outlined tile>
            <!-- toolbar -->
            <reference-tool-bar
              :title="options.title"
              :editedIndex="editedIndex"
              :createItemTitle="options.createItemTitle"
              :formTitle="formTitle"
              :dialog="dialog"
              @save="save"
              @close="close"
              @open="open"
            >
              <template v-slot:formCreateEdit>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.nameRu"
                    label="Наименование"
                    :hint="`Введине название прибора учета`"
                    :rules="[rules.required, rules.min]"
                    autofocus
                  ></v-text-field>
                </v-flex>
                <!-- <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.nameKz"
                    label="NameKz"
                    :hint="`Допускаются только буквы`"
                    :rules="[rules.required, rules.min]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.nameEn"
                    label="NameEn"
                    :hint="`Допускаются только буквы`"
                    :rules="[rules.required, rules.min]"
                  ></v-text-field>
                </v-flex> -->
                <v-flex xs12 sm12 md12>
                  <v-autocomplete
                    v-model="organizationSelect"
                    :hint="
                      `Идентификатор организации - [${organizationSelect.id}]`
                    "
                    :items="organizationList"
                    item-text="nameRu"
                    item-value="id"
                    label="Нефтянные организации"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-autocomplete
                    v-model="pipelineSelect"
                    :hint="
                      `Идентификатор нефтепровода - [${pipelineSelect.id}]`
                    "
                    :items="pipelineList"
                    item-text="nameRu"
                    item-value="id"
                    label="Нефтепровод"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-checkbox v-model="dataFromProductionFacility" label="Данные будут передаваться с другого объекта"></v-checkbox>
                </v-flex>
                <v-flex xs12 sm12 md12 v-if="dataFromProductionFacility">
                  <v-autocomplete
                    v-model="organizationSelectFromAnotherProductionFacility"
                    :hint="
                      `Идентификатор организации - [${organizationSelectFromAnotherProductionFacility.id}]`
                    "
                    :items="anotherOrganizationList"
                    item-text="nameRu"
                    item-value="id"
                    label="Организация, с которой будут передаваться данные"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12 v-if="organizationSelectFromAnotherProductionFacility && dataFromProductionFacility">
                  <v-autocomplete
                    v-model="selectAnotherProductionFacility"
                    :hint="
                      `Идентификатор объекта - [${selectAnotherProductionFacility.code}]`
                    "
                    :items="productionFacilityByOrganizationList"
                    item-text="nameRu"
                    item-value="id"
                    label="Прибор учета, с которого будут передаваться данные"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-select
                    v-model="regionSelect"
                    :hint="`Идентификатор региона - [${regionSelect.id}]`"
                    :items="regionList"
                    item-text="nameRu"
                    item-value="id"
                    label="Регион"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-select>
                </v-flex>
                <!-- <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.kilometer"
                    label="Километр"
                    :hint="`Укажите значение`"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-flex> -->
                <v-flex xs12 sm12 md12>
                  <v-select
                    v-model="statusSelect"
                    :hint="`Идентификатор статуса - [${statusSelect.id}]`"
                    :items="statusList"
                    item-text="nameRu"
                    item-value="id"
                    label="Статус"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.latitude"
                    label="Широта"
                    :hint="`Укажите значение`"
                    :rules="[rules.coordinate]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.longitude"
                    label="Долгота"
                    :hint="`Укажите значение`"
                    :rules="[rules.coordinate]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.code"
                    label="Введите код объекта"
                    :hint="`Укажите значение кода прибора учета`"
                    :rules="[rules.required, rules.code]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-select
                    v-model="facilitySelect"
                    :hint="
                      `Идентификатор типа объекта - [${facilitySelect.id}]`
                    "
                    :items="facilityList"
                    item-text="nameRu"
                    item-value="id"
                    label="Тип объекта"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-select
                    v-model="operationSelect"
                    :hint="
                      `Идентификатор типа операции - [${operationSelect.id}]`
                    "
                    :items="operationList"
                    item-text="name"
                    item-value="id"
                    label="Тип операции"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.connectionStatus"
                    label="Статус подключения"
                    :hint="
                      `Не подключен - 0, На подключении - 1, Подключен - 2`
                    "
                    :rules="[
                      rules.required,
                      v =>
                        /^[012]$/.test(v) ||
                        'Не подключен - 0, На подключении - 1, Подключен - 2'
                    ]"
                  ></v-text-field>
                </v-flex>
              </template>
            </reference-tool-bar>
            <!--Таблица с данными-->
            <reference-table
              :headers="options.headers"
              :items="options.data"
              @edit-item="editItem($event)"
              @remove-item="removeItem($event)"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import { Vue } from "vue-property-decorator";
import { get } from "vuex-pathify";
import { mapActions } from "vuex";
import { rules } from "@/util/rules";

export default Vue.extend({
  name: "ProductionFacility.vue",
  components: {
    ReferenceTable: () => import("@/components/reference/ReferenceTable.vue"),
    ReferenceToolBar: () =>
      import("@/components/reference/ReferenceToolBar.vue")
  },
  computed: {
    options: get("reference/optionsProductionFacility"),
    organizationList: get("reference/getOilOrganizations"),
    regionList: get("reference/getRegions"),
    statusList: get("reference/getStatus"),
    operationList: get("reference/getOperations"),
    pipelineList: get("reference/getPipelines"),
    facilityList: get("reference/getFacility"),
    productionFacilityByOrganizationList: get("reference/getProductionFacilityByOrganizationList"),
    formTitle() {
      return this.editedIndex === -1 ? "Создать" : "Редактировать";
    }
  },
  watch: {
    async organizationSelectFromAnotherProductionFacility(newValue, oldValue) {
      if (!newValue) {
        this.selectAnotherProductionFacility = 0;
        this.organizationSelectFromAnotherProductionFacility = 0;
      }
      try {
        await this.getProductionFacilitiesByOrganizations(newValue.id);
        if (newValue) {
          this.selectAnotherProductionFacility = 0;
        }
      } catch (error) {
        console.log("Ошибка при получении производственных объектов" + error);
      }
    },
    dataFromProductionFacility(value) {
      if (value) {
        this.anotherOrganizationList = this.organizationList.filter(org => org.id !== this.organizationSelect.id);
      } else {
        this.selectAnotherProductionFacility = 0;
        this.organizationSelectFromAnotherProductionFacility = 0;
      }
      return value;
    },
    organizationSelect() {
      this.anotherOrganizationList = this.organizationList.filter(org => org.id !== this.organizationSelect.id);
    }
    
  },
  data() {
    return {
      rules,
      dialog: false,
      editedIndex: -1,
      organizationSelect: 0,
      regionSelect: 0,
      statusSelect: 0,
      operationSelect: 0,
      pipelineSelect: 0,
      facilitySelect: 0,
      dataFromProductionFacility: false,
      organizationSelectFromAnotherProductionFacility: 0,
      selectAnotherProductionFacility: 0,
      anotherOrganizationList: 0,
    };
  },
  methods: {
    ...mapActions({
      fetchReference: "reference/fetchProductionFacility",
      fetchOrganizations: "reference/fetchOrganizations",
      fetchStatus: "reference/fetchStatus",
      fetchRegion: "reference/fetchRegion",
      fetchFacility: "reference/fetchFacility",
      fetchOperations: "reference/fetchOperations",
      fetchPipelines: "reference/fetchPipelines",
      fetchProductionFacilitiesByOrganization: "reference/fetchProductionFacilitiesByOrganization"
    }),
    async initialize() {
      await this.fetchStatus();
      await this.fetchRegion();
      await this.fetchFacility();
      await this.fetchOperations();
      await this.fetchPipelines();
      await this.fetchOrganizations();
      await this.fetchReference();
      this.organizationSelect = this.organizationList[0];
      this.statusSelect = this.statusList.find(
        status => status.name == "Active"
      );
      this.regionSelect = this.regionList[0];
      this.facilitySelect = this.facilityList[0];
      this.operationSelect = this.operationList[0];
      this.pipelineSelect = this.pipelineList[0];
      this.dataFromProductionFacility = false;
      this.organizationSelectFromAnotherProductionFacility = 0;
      this.selectAnotherProductionFacility = 0;
    },
    async getProductionFacilitiesByOrganizations(organizationId) {
      return await this.fetchProductionFacilitiesByOrganization(organizationId);
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.options.editedItem = Object.assign({}, this.options.defaultItem);
        this.organizationSelect = this.organizationList[0];
        this.statusSelect = this.statusList[0];
        this.regionSelect = this.regionList[0];
        this.facilitySelect = this.facilityList[0];
        this.operationSelect = this.operationList[0];
        this.pipelineSelect = this.pipelineList[0];
        this.dataFromProductionFacility = false,
        this.organizationSelectFromAnotherProductionFacility = 0,
        this.selectAnotherProductionFacility = 0,
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      const productionFacility = {
        id: this.options.editedItem.id,
        nameRu: this.options.editedItem.nameRu,
        nameKz: this.options.editedItem.nameKz,
        nameEn: this.options.editedItem.nameEn,
        kilometer: this.options.editedItem.kilometer,
        latitude: this.options.editedItem.latitude,
        longitude: this.options.editedItem.longitude,
        code: this.options.editedItem.code,
        connectionStatus: this.options.editedItem.connectionStatus,
        /*from_select*/
        organizationId: this.organizationSelect.id,
        oilPipelineId: this.pipelineSelect.id,
        regionId: this.regionSelect.id,
        productionFacilityTypeId: this.facilitySelect.id,
        operationTypeId: this.operationSelect.id,
        statusId: this.statusSelect.id
      };
      var productionFacilityDto;
      if (this.selectAnotherProductionFacility == 0) {
        productionFacilityDto = {
          productionFacility: productionFacility,
        };
      } else {
        productionFacilityDto = {
          productionFacility: productionFacility,
          anotherProductionFacilityCode: this.selectAnotherProductionFacility.code
        };
      }
      if (this.editedIndex > -1) {
        this.$http.put(this.options.path.base, productionFacility).then(response => {
          if (response.status === "Ok") this.initialize();
        });
      } else {
        productionFacilityDto.productionFacility.id = 0;
        var path = this.options.path.base;
        this.$http.post(path, productionFacilityDto).then(response => {
          if (response.status === "Ok") this.initialize();
        });
      }
      this.close();
    },
    editItem(item) {
      this.editedIndex = this.options.data.indexOf(item);
      this.options.editedItem = Object.assign({}, item);

      // Установка параметров пользователя как выбранных по умолчанию в select
      this.organizationSelect = {
        id: item.organization.id,
        name: item.organization.NameRu
      };
      this.statusSelect = { id: item.status.id, name: item.status.NameRu };
      this.regionSelect = { id: item.region.id, name: item.region.NameRu };
      this.facilitySelect = {
        id: item.productionFacilityType.id,
        name: item.productionFacilityType.nameRu
      };
      this.operationSelect = {
        id: item.operationType.id,
        name: item.operationType.name
      };
      this.pipelineSelect = {
        id: item.oilPipeline.id,
        name: item.oilPipeline.nameRu
      };

      this.open();
    },
    removeItem(item) {
      if (confirm("Вы уверены что хотите удалить объект?")) {
        const model = Object.assign({}, item);
        this.$http
          .delete(this.options.path.base, { data: model })
          .then(response => {
            if (response.status === "Ok") this.initialize();
          });
      }
    }
  },
  async created() {
    await this.initialize();
  }
});
</script>
